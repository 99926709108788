@use 'styles/_colors.scss';

$md1: 8px;
$md2: 2*$md1;
$md3: 3*$md1;
$md4: 4*$md1;

.container {
    padding-top: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .content {
        width: 25%;
        * {
            margin-bottom: 20px;
        }
        .primaryBtn {
            margin-top: $md2;
            width: 50%;
        }
        .quizContent {
            margin-top: 20%;
        }
        .title {
            font-weight: bold;
            font-size: 48px;
            line-height: 120%;
        }
    }
    > .lottie {
        margin-left: 10%;
    }
}